import React from 'react'
import { FaShoppingCart } from "react-icons/fa"
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

const Navbar = () => {

  const { Cart } = useSelector((state) => state)

  return (
    <div className='bg-slate-900'>
      <nav className='flex justify-between items-center h-20 max-w-6xl mx-auto'>

        <NavLink to="/">
          <img src='../logo.png' className='h-14 ml-5' />
        </NavLink>
        <div className='flex items-center text-slate-100 text-xl mr-5 gap-6'>
          <NavLink to="/" >
            <p>Home</p>
          </NavLink>

          <NavLink to="/cart">
            <div className='relative'>
              <FaShoppingCart className='text-2xl'/>

              {
                Cart.length > 0 &&
                <span className='absolute -top-2 -right-2 rounded-full  text-sm w-5 h-5 bg-green-600 text-center animate-bounce' >{Cart.length}</span>
              }
            </div>
          </NavLink>

        </div>
      </nav>
    </div>
  )
}

export default Navbar