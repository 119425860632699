import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux'
import { add, remove } from '../redux/Slices/CartSlice';
import "../index.css"

const Card = ({ prod }) => {

    const { Cart } = useSelector((state) => state);
    const [quantity, setQuantity] = useState(1);

    const dispatch = useDispatch();

    const addToCart = () => {
        dispatch(add([prod, quantity, prod.id]));
        toast.success("Item Added to Cart");
    }

    const clickHandler = (event) => {
        setQuantity(event.target.value)
    }

    return (
        <div className='flex flex-col items-center justify-between 
        hover:scale-110 transition duration-300 ease-in gap-3 p-4 mt-10 ml-5 rounded-xl border-2 border-black item'>

            <p className="text-gray-800 font-semibold text-lg text-left truncate w-40 mt-1">{prod.title.split(" ").slice(0, 5).join(" ") + " ..."}</p>

            <p className="w-40 text-gray-600 font-normal text-[11px] text-left">{prod.description.split(" ").slice(0, 10).join(" ") + " ..."}</p>

            <div className='h-[180px]'>
                <img src={prod.image} className='w-full h-full' />
            </div>

            <div className='flex justify-between items-center w-full mt-5'>
                <h4 className="text-[#1faa59] text-lg font-bold"><span>&#x24;</span> {prod.price}</h4>

                <select id="quantity" value={quantity} name='qnt' className='w-12 mr-4 text-center bg-white text-lg outline-black outline-none' onChange={clickHandler}>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                </select>

            </div>

            <div>
                {
                    <button className="text-gray-700 border-2 border-gray-700 rounded-full font-semibold text-[12px] p-1 px-3 uppercase hover:bg-green-800 hover:text-white transition duration-300 ease-in mt-2" onClick={addToCart}>
                        Add to Cart</button>
                }
            </div>

        </div>
    )
}

export default Card