import React, { useEffect, useState } from 'react'
import Spinner from "../components/Spinner"
import Card from '../components/Card';

const Home = () => {

    const API = "https://fakestoreapi.com/products";
    const [loading, setLoading] = useState(false);
    const [prods, setProds] = useState([]);

    async function fetchProductData()
    {
    
        setLoading(true);
        try{
            const res = await fetch(API);
            const data = await res.json();
            
            setProds(data);
        }
        catch{
            alert("Error Fetching Data");
        }
        
        setLoading(false);
    }

    useEffect(() => {
        fetchProductData();
    },[])

    return (
        <div>
            {
                loading ? <Spinner/> :
                prods.length > 0 ? 
                (<div className='grid grid-cols-4 max-w-6xl p-2 mx-auto space-y-10 space-x-5 mb-8 z-10'>
                    {
                        prods.map((prod) => (
                            <Card key={prod.id} prod = {prod}/>
                        ))
                    }
                </div>) :
                
                (<div className='flex justify-center items-center h-[500px] text-2xl '>No Products Found</div>)
            }
        </div>
    )
}

export default Home         