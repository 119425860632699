import React, { useState } from 'react'
import { RiDeleteBin7Fill } from "react-icons/ri";
import { useDispatch } from 'react-redux';
import { remove, decQuantity } from '../redux/Slices/CartSlice';
import toast from 'react-hot-toast';

const CartItem = ({item,qnt}) => {

    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(qnt);

    const removeFromCart = () => {
        if(quantity == 1)
            dispatch(remove(item.id));
        else
        {
            setQuantity(quantity-1);
            dispatch(decQuantity(item.id));
        }
        toast.error("Item Removed from Cart");
    }

    return (
        <div className='flex gap-12 justify-center items-center border-b-[3px] border-black pb-8 min-h-60'>
            <div className='h-[180px]'>
                <img src={item.image} className='h-full w-full scale-100' />
            </div>
            <div className='flex flex-col gap-6 w-7/12'>
                <h1 className='font-bold text-xl'>{item.title}</h1>
                <p className='text-sm'>{item.description.split(" ").slice(0, 20).join(" ") + " ..."}</p>

                <div className='flex justify-between mt-2'>
                    <h4 className="text-[#1faa59] text-lg font-bold"><span>&#x24;</span> {item.price}</h4>
                    <p>Qunatity : <span className='font-semibold ml-1'>{quantity}</span></p>
                    <div className='flex justify-center text-red-800 bg-red-200 w-10 h-10 rounded-full -mt-1' onClick={removeFromCart}>
                        <button className=''><RiDeleteBin7Fill />
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CartItem