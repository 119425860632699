import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import CartItem from "../components/CartItem"
import { clear } from '../redux/Slices/CartSlice';

const Cart = () => {

    const { Cart } = useSelector((state) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [totalAmount, setTotalAmount] = useState(0);
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        console.log(Cart);
        setTotalAmount(Cart.reduce((acc, curr) => acc + curr.at(0).price * Number(curr.at(1)), 0));
        setTotalItems(Cart.reduce((acc, curr) => acc + Number(curr.at(1)), 0));

    },[Cart])

    return (
        <div>
            {
                Cart.length > 0 ?
                    (<div className='flex justify-evenly gap-0 mt-16'>
                        <div className='flex flex-col w-5/12 gap-14'>
                            {
                                Cart.map((item, index) => (
                                    <CartItem key={item.at(0).id} item={item.at(0)} qnt={item.at(1)} itemIndex={index} />
                                ))
                            }

                        </div>

                        <div className='flex flex-col justify-between h-[350px] mt-12'>
                            <div className='flex flex-col'>
                                <p className=' text-green-700 font-bold uppercase text-lg'>Your Cart</p>
                                <p className=' text-green-700 font-bold uppercase text-5xl'>Summary</p>
                                <p className='mt-5 text-xl'>
                                    <span className='font-semibold mr-1'>Total Items</span> : {totalItems}
                                </p>
                            </div>

                            <div className='flex flex-col gap-4'>
                                <p className='font-bold text-xl'>
                                    <span className='font-semibold mr-2'>Total Amount</span> : <span className='ml-2'>&#x24;</span> {totalAmount.toFixed(2)}
                                </p>
                                <button className='text-white font-bold bg-green-700 px-4 py-3 rounded-lg hover:bg-green-900 duration-300 text-lg'>Checkout Now</button>
                            </div>
                        </div>

                        <div className='absolute right-32 top-[100px] bg-red-500 px-4 py-2 rounded-lg text-white hover:bg-red-700 duration-300 cursor-pointer font-semibold' onClick={() => {dispatch(clear())}}>Clear Cart</div>

                    </div>) :

                    (<div className='flex flex-col gap-6 items-center justify-center h-[600px]'>
                        <h2 className='text-2xl'>Your Cart is empty!</h2>
                        <button className='bg-green-500 px-4 py-2 text-xl text-white rounded-xl hover:bg-green-700 duration-300 font-semibold' onClick={() => navigate("/")}>SHOP NOW</button>
                    </div>)
            }

            

        </div >
    )
}

export default Cart