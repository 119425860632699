import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";


export const CartSlice = createSlice({
    name: "Cart",
    initialState: [],
    reducers: {
        add: (state, action) => {
            var flag = false;
            state.forEach((item,i) => {
                if(item.at(2) == action.payload.at(2)) 
                {
                    state[i][1] = action.payload.at(1);
                    flag = true;
                }
            });

            if(!flag)
                state.push(action.payload);            
        },

        remove: (state, action) => {
            return state.filter((item) => item.at(2) != action.payload);
        },

        clear: (state) => {
            state.length = 0;
        },

        decQuantity : (state,action) => {
            state.forEach((item,i) => {
                if(item.at(2) == action.payload) 
                    state[i][1] -= 1;
            });
        }
    }
});

export const { add, remove, clear, decQuantity } = CartSlice.actions;
export default CartSlice.reducer;